<script setup lang="ts">
import { onMounted, reactive, watch } from 'vue'

import router from '@/router'
import Row from 'primevue/row'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import ColumnGroup from 'primevue/columngroup'
import Tag from 'primevue/tag'

import { useToast } from 'primevue/usetoast'
import { formatDate } from '@/utils/helpers'
import { useConfirm } from 'primevue/useconfirm'
import { EyeIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { useVerificationList } from '@/services/verifications/useVerificationList'
import { useRemoveVerification } from '@/services/verifications/useRemoveVerification'
import OnboardingSidebar from '@/components/Sidebar/OnboardingSidebar.vue'
import { useHasPermission } from '@/utils/useHasPermission'
import { PermissionsCode } from '@/constants/permissions'
import { useAuthStore } from '@/stores/authentication'

const toast = useToast()
const confirm = useConfirm()
const authStore = useAuthStore()
const { hasPermissionCode } = useHasPermission()

const DashboardState = reactive({
  verifications: [],
  loading: true,
  pageRows: 10,
  pageNumber: 1,
  totalRecords: 0
})

const fetchVerificationData = (pageNumber: number = 1) => {
  useVerificationList(pageNumber).then(({ data, error, status }) => {
    if (status.value == 200) {
      DashboardState.verifications = data.value?.results
      DashboardState.totalRecords = data.value?.count
      DashboardState.loading = false
    }
    if (status.value == 429) {
      DashboardState.value = false
      toast.add({
        severity: 'warn',
        summary: 'Warning Message',
        detail: 'Attempt limit exceeded. Try again after a minute',
        life: 7000
      })
    }
  })
}

const onConfirmDelete = (data: any) => {
  confirm.require({
    message: `Are you sure you want to remove verification ID "${data.hash}"?`,
    header: 'Confirmation',
    acceptLabel: 'Confirm',
    rejectLabel: 'Cancel',
    rejectClass: 'bg-red-500 border-red-600 hover:bg-red-600 hover:border-red-600',
    accept: () => {
      DashboardState.loading = true
      useRemoveVerification(data?.hash).then(() => {
        DashboardState.totalRecords -= 1
        DashboardState.pageRows -= 1
        DashboardState.verifications = DashboardState.verifications?.filter(
          (val: any) => val.hash !== data?.hash
        )
        toast.add({
          summary: 'Success Message',
          severity: 'success',
          detail: 'Verification id deleted successfully',
          life: 7000
        })
        // If total records are grater then 10 and row len is less than 7 then re-fetch the table records.
        if (DashboardState.pageRows < 7 && DashboardState.totalRecords > 10) {
          fetchVerificationData(DashboardState.pageNumber)
          DashboardState.pageRows = 10
        }
        DashboardState.loading = false
      })
    }
  })
}

const onPageChange = (event: any) => {
  DashboardState.pageNumber = event.page + 1
}

// Watch changes
watch(
  () => DashboardState.pageNumber,
  () => {
    DashboardState.loading = true
    fetchVerificationData(DashboardState.pageNumber)
    DashboardState.loading = false
  }
)

// On Mount
onMounted(() => {
  if (hasPermissionCode([PermissionsCode.CanViewVerification])) fetchVerificationData()
})
</script>

<template>
  <main class="mx-2 lg:mx-10">
    <!--  Heading-->
    <h1 class="text-font-main text-xl font-semibold mt-5">Verifications</h1>
    <!-- TODO: Add Search & Create Verification Row-->
    <div class="flex justify-end">
      <Button
        label="Create Verification"
        severity="success"
        size="small"
        type="button"
        v-if="hasPermissionCode([PermissionsCode.CanCreateVerification])"
        @click.prevent="router.push({ name: 'create-verification' })"
      />
    </div>
    <!--    Data Table-->
    <div class="shadow-md my-4">
      <DataTable
        :loading="DashboardState.loading"
        :paginator="DashboardState.totalRecords > 0"
        :rows="DashboardState.pageRows"
        :total-records="DashboardState.totalRecords"
        :value="DashboardState.verifications"
        data-key="id"
        size="small"
        lazy
        striped-rows
        @page="onPageChange($event)"
        v-if="hasPermissionCode([PermissionsCode.CanViewVerification])"
      >
        <!--        Empty data slot-->
        <template #empty>
          <div class="text-center py-28">
            <p v-if="!DashboardState.loading" class="text-center text-font-main">
              Get started by
              <router-link :to="{ name: 'settings' }" class="font-medium text-secondary-600">
                setting up a custom domain
              </router-link>
              to generate <br />
              trusted verifications, or set it up later.
            </p>
          </div>
        </template>
        <!--        Loading slot-->
        <template #loading>
          <p class="text-font-light">Loading... Please wait.</p>
        </template>
        <ColumnGroup type="header">
          <Row>
            <Column header="Verification ID" style="width: 15%" />
            <Column header="Name" />
            <Column header="Created By" style="width: 12%" />
            <Column header="Created On" style="width: 10%" />
            <Column :colspan="2" header="Actions" style="width: 15%" />
          </Row>
        </ColumnGroup>
        <Column field="hash" header="Verification ID" />
        <Column class="max-w-80" header="Name">
          <template #body="{ data }">
            <span class="pr-2">{{ data?.document_name }}</span>
            <Tag v-if="data?.has_demo_domain" severity="warn"> For Demo</Tag>
          </template>
        </Column>
        <Column field="created_name" header="Created By" />
        <Column field="created" header="Created On">
          <!--suppress JSUnresolvedReference -->
          <template #body="{ data }">
            {{ formatDate(data?.created) }}
          </template>
        </Column>
        <Column :exportable="false" header="Actions">
          <template #body="{ data }">
            <a :href="data?.reference_url" target="_blank">
              <button class="action-button mr-4">
                <EyeIcon class="w-5 stroke-1.5" />
              </button>
            </a>
            <button
              v-if="hasPermissionCode([PermissionsCode.CanRemoveVerification])"
              class="action-button"
              @click="onConfirmDelete(data)"
            >
              <TrashIcon class="w-5 stroke-1.5" />
            </button>
          </template>
        </Column>
      </DataTable>
    </div>

    <!-- Onboarding Sidebar-->
    <OnboardingSidebar />
    <div v-if="!authStore.getHasSetupCustomDomain" class="font-jost text-red-600">
      <span class="font-medium">
        You haven't setup your custom domain.
        <router-link :to="{ name: 'settings' }" class="font-medium text-primary-600">
          Setup now!
        </router-link>
      </span>
    </div>
    <!--    Setup Custom Domain Sticky toast at bottom-->
  </main>
</template>

<style lang="postcss" scoped>
.action-button {
  @apply p-2 rounded-full hover:bg-surface-100;
}
</style>
