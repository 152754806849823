// noinspection JSUnusedLocalSymbols,SpellCheckingInspection

import Tailwind from 'primevue/passthrough/tailwind'
import { usePassThrough } from 'primevue/passthrough'
import type { DividerPassThroughMethodOptions } from 'primevue/divider'
import type { PasswordPassThroughMethodOptions } from 'primevue/password'
import type { InputTextPassThroughMethodOptions } from 'primevue/inputtext'

// PT Based on Taiwlind Primevue LARA Theme
export const QRMarkTailwindPT: object = usePassThrough(
  Tailwind,
  {
    // Button
    button: {
      root: ({ props: e, context: r, parent: t }: any) => ({
        class: [
          'relative',
          'items-center inline-flex text-center align-bottom justify-center',
          'leading-[normal]',
          {
            'px-4 py-2.5': e.size === null && e.label !== null,
            'text-sm py-2 px-3': e.size === 'small',
            'text-xl py-3 px-4': e.size === 'large'
          },
          { 'w-12 p-0 py-3': e.label == null },
          { 'shadow-lg': e.raised },
          { 'rounded-md': !e.rounded, 'rounded-full': e.rounded },
          { 'rounded-none first:rounded-l-md last:rounded-r-md': t.instance.$name == 'InputGroup' },
          { 'text-primary-600 bg-transparent border-transparent': e.link },
          { 'text-white bg-gray-500 border border-gray-500': e.plain && !e.outlined && !e.text },
          { 'text-surface-500': e.plain && e.text },
          { 'text-surface-500 border border-gray-500': e.plain && e.outlined },
          { 'bg-transparent border-transparent': e.text && !e.plain },
          { 'bg-transparent border': e.outlined && !e.plain },
          {
            'text-white dark:text-surface-900':
              !e.link && e.severity === null && !e.text && !e.outlined && !e.plain,
            'bg-primary-500 dark:bg-primary-400':
              !e.link && e.severity === null && !e.text && !e.outlined && !e.plain,
            'border border-primary-500 dark:border-primary-400':
              !e.link && e.severity === null && !e.text && !e.outlined && !e.plain
          },
          { 'text-primary-500 dark:text-primary-400': e.text && e.severity === null && !e.plain },
          {
            'text-primary-500 border border-primary-500 hover:bg-primary-300/20':
              e.outlined && e.severity === null && !e.plain
          },
          {
            'text-white dark:text-surface-900':
              e.severity === 'secondary' && !e.text && !e.outlined && !e.plain,
            'bg-surface-500 dark:bg-surface-400':
              e.severity === 'secondary' && !e.text && !e.outlined && !e.plain,
            'border border-surface-500 dark:border-surface-400':
              e.severity === 'secondary' && !e.text && !e.outlined && !e.plain
          },
          {
            'text-surface-500 dark:text-surface-300':
              e.text && e.severity === 'secondary' && !e.plain
          },
          {
            'text-surface-500 dark:text-surface-300 border border-surface-500 hover:bg-surface-300/20':
              e.outlined && e.severity === 'secondary' && !e.plain
          },
          {
            'text-white dark:text-primary-900':
              e.severity === 'success' && !e.text && !e.outlined && !e.plain,
            'bg-primary-500 dark:bg-primary-400':
              e.severity === 'success' && !e.text && !e.outlined && !e.plain,
            'border border-primary-500 dark:border-primary-400':
              e.severity === 'success' && !e.text && !e.outlined && !e.plain
          },
          {
            'text-primary-800 dark:text-primary-400': e.text && e.severity === 'success' && !e.plain
          },
          {
            'text-primary-800 border border-primary-500 hover:bg-primary-300/20':
              e.outlined && e.severity === 'success' && !e.plain
          },
          {
            'text-white dark:text-surface-900':
              e.severity === 'info' && !e.text && !e.outlined && !e.plain,
            'bg-secondary-500 dark:bg-secondary-400':
              e.severity === 'info' && !e.text && !e.outlined && !e.plain,
            'border border-secondary-500 dark:border-secondary-400':
              e.severity === 'info' && !e.text && !e.outlined && !e.plain
          },
          {
            'text-secondary-500 dark:text-secondary-400':
              e.text && e.severity === 'info' && !e.plain
          },
          {
            'text-secondary-500 border border-secondary-500 hover:bg-secondary-300/20 ':
              e.outlined && e.severity === 'info' && !e.plain
          },
          {
            'text-white dark:text-surface-900':
              e.severity === 'warning' && !e.text && !e.outlined && !e.plain,
            'bg-orange-500 dark:bg-orange-400':
              e.severity === 'warning' && !e.text && !e.outlined && !e.plain,
            'border border-orange-500 dark:border-orange-400':
              e.severity === 'warning' && !e.text && !e.outlined && !e.plain
          },
          {
            'text-orange-500 dark:text-orange-400': e.text && e.severity === 'warning' && !e.plain
          },
          {
            'text-orange-500 border border-orange-500 hover:bg-orange-300/20':
              e.outlined && e.severity === 'warning' && !e.plain
          },
          {
            'text-white dark:text-surface-900':
              e.severity === 'help' && !e.text && !e.outlined && !e.plain,
            'bg-purple-500 dark:bg-purple-400':
              e.severity === 'help' && !e.text && !e.outlined && !e.plain,
            'border border-purple-500 dark:border-purple-400':
              e.severity === 'help' && !e.text && !e.outlined && !e.plain
          },
          { 'text-purple-500 dark:text-purple-400': e.text && e.severity === 'help' && !e.plain },
          {
            'text-purple-500 border border-purple-500 hover:bg-purple-300/20':
              e.outlined && e.severity === 'help' && !e.plain
          },
          {
            'text-white dark:text-surface-900':
              e.severity === 'danger' && !e.text && !e.outlined && !e.plain,
            'bg-red-500 dark:bg-red-400':
              e.severity === 'danger' && !e.text && !e.outlined && !e.plain,
            'border border-red-500 dark:border-red-400':
              e.severity === 'danger' && !e.text && !e.outlined && !e.plain
          },
          { 'text-red-500 dark:text-red-400': e.text && e.severity === 'danger' && !e.plain },
          {
            'text-red-500 border border-red-500 hover:bg-red-300/20':
              e.outlined && e.severity === 'danger' && !e.plain
          },
          'focus:outline-none focus:outline-offset-0 focus:ring',
          { 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50': e.link },
          { 'hover:bg-gray-600 hover:border-gray-600': e.plain && !e.outlined && !e.text },
          { 'hover:bg-surface-300/20': e.plain && (e.text || e.outlined) },
          {
            'hover:bg-primary-600 dark:hover:bg-primary-300 hover:border-primary-600 dark:hover:border-primary-300':
              !e.link && e.severity === null && !e.text && !e.outlined && !e.plain
          },
          { 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50': e.severity === null },
          { 'hover:bg-primary-300/20': (e.text || e.outlined) && e.severity === null && !e.plain },
          {
            'hover:bg-surface-600 dark:hover:bg-surface-300 hover:border-surface-600 dark:hover:border-surface-300':
              e.severity === 'secondary' && !e.text && !e.outlined && !e.plain
          },
          {
            'focus:ring-surface-400/50 dark:focus:ring-surface-300/50': e.severity === 'secondary'
          },
          {
            'hover:bg-surface-300/20':
              (e.text || e.outlined) && e.severity === 'secondary' && !e.plain
          },
          {
            'hover:bg-primary-600 dark:hover:bg-primary-300 hover:border-primary-600 dark:hover:border-primary-300':
              e.severity === 'success' && !e.text && !e.outlined && !e.plain
          },
          { 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50': e.severity === 'success' },
          {
            'hover:bg-primary-300/20':
              (e.text || e.outlined) && e.severity === 'success' && !e.plain
          },
          {
            'hover:bg-secondary-600 dark:hover:bg-secondary-300 hover:border-secondary-600 dark:hover:border-secondary-300':
              e.severity === 'info' && !e.text && !e.outlined && !e.plain
          },
          { 'focus:ring-secondary-400/50 dark:focus:ring-secondary-300/50': e.severity === 'info' },
          {
            'hover:bg-secondary-300/20': (e.text || e.outlined) && e.severity === 'info' && !e.plain
          },
          {
            'hover:bg-orange-600 dark:hover:bg-orange-300 hover:border-orange-600 dark:hover:border-orange-300':
              e.severity === 'warning' && !e.text && !e.outlined && !e.plain
          },
          { 'focus:ring-orange-400/50 dark:focus:ring-orange-300/50': e.severity === 'warning' },
          {
            'hover:bg-orange-300/20': (e.text || e.outlined) && e.severity === 'warning' && !e.plain
          },
          {
            'hover:bg-purple-600 dark:hover:bg-purple-300 hover:border-purple-600 dark:hover:border-purple-300':
              e.severity === 'help' && !e.text && !e.outlined && !e.plain
          },
          { 'focus:ring-purple-400/50 dark:focus:ring-purple-300/50': e.severity === 'help' },
          { 'hover:bg-purple-300/20': (e.text || e.outlined) && e.severity === 'help' && !e.plain },
          {
            'hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300':
              e.severity === 'danger' && !e.text && !e.outlined && !e.plain
          },
          { 'focus:ring-red-400/50 dark:focus:ring-red-300/50': e.severity === 'danger' },
          { 'hover:bg-red-300/20': (e.text || e.outlined) && e.severity === 'danger' && !e.plain },
          { 'opacity-30 pointer-events-none cursor-default': r.disabled },
          'transition duration-200 ease-in-out',
          'cursor-pointer overflow-hidden select-none'
        ]
      }),
      label: ({ props: e }: any) => ({
        class: [
          'duration-200',
          'font-semibold',
          { 'hover:underline': e.link },
          { 'flex-initial': e.label !== null, 'invisible w-0': e.label == null }
        ]
      }),
      icon: ({ props: e }: any) => ({
        class: [
          'mx-0',
          {
            'mr-2': e.iconPos == 'left' && e.label != null,
            'ml-2 order-1': e.iconPos == 'right' && e.label != null,
            'mb-2': e.iconPos == 'top' && e.label != null,
            'mt-2': e.iconPos == 'bottom' && e.label != null
          }
        ]
      }),
      loadingicon: ({ props: e }: any) => ({
        class: [
          'h-4 w-4',
          'mx-0',
          {
            'mr-2': e.iconPos == 'left' && e.label != null,
            'ml-2 order-1': e.iconPos == 'right' && e.label != null,
            'mb-2': e.iconPos == 'top' && e.label != null,
            'mt-2': e.iconPos == 'bottom' && e.label != null
          },
          'animate-spin'
        ]
      }),
      badge: ({ props: e }: any) => ({
        class: [{ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': e.badge }]
      })
    },
    // Card
    card: {
      root: {
        class: [
          'bg-white',
          'rounded-md',
          'shadow-md',
          'bg-surface-0 dark:bg-surface-900',
          'text-surface-700 dark:text-surface-0'
        ]
      },
      body: {
        class: 'p-2'
      },
      title: {
        class: 'font-semibold text-lg text-font-light py-2'
      },
      subtitle: {
        class: ['font-normal', 'mb-2', 'text-surface-600 dark:text-surface-0/60']
      },
      content: {
        class: 'py-2'
      },
      footer: {
        class: 'pt-4'
      }
    },
    // Divider
    divider: {
      root: ({ props: e }: DividerPassThroughMethodOptions) => ({
        class: [
          'flex relative',
          { 'justify-center': e.layout == 'vertical' },
          { 'items-center': e.layout == 'vertical' },
          {
            'justify-start': e.align == 'left' && e.layout == 'horizontal',
            'justify-center': e.align == 'center' && e.layout == 'horizontal',
            'justify-end': e.align == 'right' && e.layout == 'horizontal',
            'items-center': e.align == 'top' && e.layout == 'vertical',
            'items-start': e.align == 'center' && e.layout == 'vertical',
            'items-end': e.align == 'bottom' && e.layout == 'vertical'
          },
          {
            'my-5 mx-0 py-0 px-5': e.layout == 'horizontal',
            'mx-4 md:mx-5 py-5': e.layout == 'vertical'
          },
          { 'w-full': e.layout == 'horizontal', 'min-h-full': e.layout == 'vertical' },
          'before:block',
          {
            'before:absolute before:left-0 before:top-1/2': e.layout == 'horizontal',
            'before:absolute before:left-1/2 before:top-0 before:transform before:-translate-x-1/2':
              e.layout == 'vertical'
          },
          {
            'before:w-full': e.layout == 'horizontal',
            'before:min-h-full': e.layout == 'vertical'
          },
          {
            'before:border-solid': e.type == 'solid',
            'before:border-dotted': e.type == 'dotted',
            'before:border-dashed': e.type == 'dashed'
          },
          {
            'before:border-t before:border-surface-200 before:dark:border-surface-600':
              e.layout == 'horizontal',
            'before:border-l before:border-surface-200 before:dark:border-surface-600':
              e.layout == 'vertical'
          }
        ]
      }),
      content: {
        class: [
          'px-4 z-10',
          'bg-surface-0 dark:bg-surface-800',
          'text-xl text-font-main subpixel-antialiased'
        ]
      }
    },
    // InputText
    inputtext: {
      root: ({ props: e, context: r, parent: t }: InputTextPassThroughMethodOptions) => ({
        class: [
          'leading-none',
          'm-0',
          {
            'px-4 py-4 w-full': e.size == 'large',
            'px-1.5 py-1.5 w-full': e.size == 'small',
            'px-2 py-2  w-full': e.size == null
          },
          { 'rounded-md': t.instance.$name !== 'InputGroup' },
          { 'first:rounded-l-md rounded-none last:rounded-r-md': t.instance.$name == 'InputGroup' },
          { 'border-0 border-y border-l last:border-r': t.instance.$name == 'InputGroup' },
          //{ 'first:ml-0 ml-[-1px]': t.instance.$name == 'InputGroup' && !e.showButtons },
          'text-surface-600 dark:text-surface-200',
          'placeholder:text-surface-400 dark:placeholder:text-surface-500',
          'bg-surface-0 dark:bg-surface-900',
          'border-2 dark:border-surface-600',
          {
            'hover:border-primary-500 dark:hover:border-primary-400': !r.disabled,
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 dark:focus:ring-primary-400/50':
              !r.disabled,
            'opacity-60 select-none pointer-events-none cursor-default': r.disabled
          },
          'appearance-none',
          'transition-colors duration-200'
        ]
      })
    },
    // InputNumber
    inputnumber: {
      root: ({ props, parent }: any) => ({
        class: [
          // Flex
          'inline-flex',
          { 'flex-col': props.showButtons && props.buttonLayout == 'vertical' },
          { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },
          // Shape
          {
            'first:rounded-l-md rounded-none last:rounded-r-md':
              parent.instance.$name == 'InputGroup' && !props.showButtons
          },
          {
            'border-0 border-y border-l last:border-r border-surface-300 dark:border-surface-600':
              parent.instance.$name == 'InputGroup' && !props.showButtons
          },
          { 'first:ml-0 -ml-px': parent.instance.$name == 'InputGroup' && !props.showButtons },
          //Sizing
          { '!w-16': props.showButtons && props.buttonLayout == 'vertical' }
        ]
      }),
      input: {
        root: ({ parent, context }: any) => {
          var _a, _b, _c
          return {
            class: [
              // Display
              'flex flex-auto',
              // Font
              'font-sans leading-none',
              //Text
              {
                'text-center': parent.props.showButtons && parent.props.buttonLayout == 'vertical'
              },
              // Spacing
              'p-3',
              'm-0',
              // Shape
              'rounded-lg',
              { 'rounded-tr-none rounded-br-none': parent.props.showButtons },
              {
                'rounded-tl-none rounded-bl-none':
                  parent.props.showButtons && parent.props.buttonLayout == 'horizontal'
              },
              {
                'rounded-none': parent.props.showButtons && parent.props.buttonLayout == 'vertical'
              },
              {
                '!rounded-none':
                  ((_a = parent.instance.$parentInstance) == null ? void 0 : _a.$name) ==
                    'InputGroup' && !parent.props.showButtons
              },
              {
                'border-0':
                  ((_b = parent.instance.$parentInstance) == null ? void 0 : _b.$name) ==
                    'InputGroup' && !parent.props.showButtons
              },
              // Colors
              'text-surface-600 dark:text-surface-200',
              'placeholder:text-surface-400 dark:placeholder:text-surface-500',
              'bg-surface-0 dark:bg-surface-900',
              'border',
              { 'border-surface-300 dark:border-surface-600': !parent.props.invalid },
              // Invalid State
              { 'border-red-500 dark:border-red-400': parent.props.invalid },
              // States
              { 'hover:border-primary-500 dark:hover:border-primary-400': !parent.props.invalid },
              'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 dark:focus:ring-primary-400/50 focus:z-10',
              { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled },
              // Filled State *for FloatLabel
              {
                filled:
                  ((_c = parent.instance) == null ? void 0 : _c.$name) == 'FloatLabel' &&
                  context.filled
              },
              //Position
              {
                'order-2':
                  parent.props.buttonLayout == 'horizontal' ||
                  parent.props.buttonLayout == 'vertical'
              }
            ]
          }
        }
      },
      buttongroup: ({ props }: any) => ({
        class: [
          // Flex
          'flex',
          'flex-col'
        ]
      }),
      incrementbutton: {
        root: ({ parent }: any) => ({
          class: [
            // Display
            'flex flex-auto',
            // Alignment
            'items-center',
            'justify-center',
            'text-center align-bottom',
            //Position
            'relative',
            { 'order-3': parent.props.showButtons && parent.props.buttonLayout == 'horizontal' },
            { 'order-1': parent.props.showButtons && parent.props.buttonLayout == 'vertical' },
            //Color
            'text-white dark:text-surface-900',
            'bg-primary-500 dark:bg-primary-400',
            'border border-primary-500 dark:border-primary-400',
            // Sizing
            'w-[3rem]',
            { 'px-4 py-3': parent.props.showButtons && parent.props.buttonLayout !== 'stacked' },
            { 'p-0': parent.props.showButtons && parent.props.buttonLayout == 'stacked' },
            { 'w-full': parent.props.showButtons && parent.props.buttonLayout == 'vertical' },
            // Shape
            'rounded-md',
            {
              'rounded-tl-none rounded-br-none rounded-bl-none':
                parent.props.showButtons && parent.props.buttonLayout == 'stacked'
            },
            {
              'rounded-bl-none rounded-tl-none':
                parent.props.showButtons && parent.props.buttonLayout == 'horizontal'
            },
            {
              'rounded-bl-none rounded-br-none':
                parent.props.showButtons && parent.props.buttonLayout == 'vertical'
            },
            //States
            'focus:outline-none focus:outline-offset-0 focus:ring',
            'hover:bg-primary-600 dark:hover:bg-primary-300 hover:border-primary-600 dark:hover:border-primary-300',
            //Misc
            'cursor-pointer overflow-hidden select-none'
          ]
        }),
        label: {
          class: 'h-0 w-0'
        }
      },
      decrementbutton: {
        root: ({ parent }: any) => ({
          class: [
            // Display
            'flex flex-auto',
            // Alignment
            'items-center',
            'justify-center',
            'text-center align-bottom',
            //Position
            'relative',
            { 'order-1': parent.props.showButtons && parent.props.buttonLayout == 'horizontal' },
            { 'order-3': parent.props.showButtons && parent.props.buttonLayout == 'vertical' },
            //Color
            'text-white dark:text-surface-900',
            'bg-primary-500 dark:bg-primary-400',
            'border border-primary-500 dark:border-primary-400',
            // Sizing
            'w-[3rem]',
            { 'px-4 py-3': parent.props.showButtons && parent.props.buttonLayout !== 'stacked' },
            { 'p-0': parent.props.showButtons && parent.props.buttonLayout == 'stacked' },
            { 'w-full': parent.props.showButtons && parent.props.buttonLayout == 'vertical' },
            // Shape
            'rounded-md',
            {
              'rounded-tr-none rounded-tl-none rounded-bl-none':
                parent.props.showButtons && parent.props.buttonLayout == 'stacked'
            },
            {
              'rounded-tr-none rounded-br-none ':
                parent.props.showButtons && parent.props.buttonLayout == 'horizontal'
            },
            {
              'rounded-tr-none rounded-tl-none ':
                parent.props.showButtons && parent.props.buttonLayout == 'vertical'
            },
            //States
            'focus:outline-none focus:outline-offset-0 focus:ring',
            'hover:bg-primary-600 dark:hover:bg-primary-300 hover:border-primary-600 dark:hover:border-primary-300',
            //Misc
            'cursor-pointer overflow-hidden select-none'
          ]
        }),
        label: {
          class: 'h-0 w-0'
        }
      }
    },
    // Password
    password: {
      root: ({ props: e }: PasswordPassThroughMethodOptions) => ({
        class: [
          'inline-flex relative',
          { 'opacity-60 select-none pointer-events-none cursor-default': e.disabled }
        ]
      }),
      panel: {
        class: [
          'p-4',
          'border-0 dark:border',
          'shadow-md rounded-md',
          'bg-surface-0 dark:bg-surface-900',
          'text-surface-700 dark:text-white/80',
          'dark:border-surface-700'
        ]
      },
      meter: {
        class: [
          'overflow-hidden',
          'relative',
          'border-0',
          'h-3',
          'mb-2',
          'bg-surface-100 dark:bg-surface-700'
        ]
      },
      meterlabel: ({ instance: e }: any) => {
        var r, t, o
        return {
          class: [
            'h-full',
            {
              'bg-red-500 dark:bg-red-400/50':
                ((r = e == null ? void 0 : e.meter) == null ? void 0 : r.strength) == 'weak',
              'bg-orange-500 dark:bg-orange-400/50':
                ((t = e == null ? void 0 : e.meter) == null ? void 0 : t.strength) == 'medium',
              'bg-primary-500 dark:bg-primary-400/50':
                ((o = e == null ? void 0 : e.meter) == null ? void 0 : o.strength) == 'strong'
            },
            'transition-all duration-1000 ease-in-out'
          ]
        }
      },
      showicon: {
        class: ['absolute top-1/2 right-3 -mt-2', 'text-surface-600 dark:text-white/70']
      },
      hideicon: {
        class: ['absolute top-1/2 right-3 -mt-2', 'text-surface-600 dark:text-white/70']
      },
      transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass:
          'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0'
      },
      info: 'text-font-light text-sm'
    },
    //TextArea
    textarea: {
      root: ({ context: e }: any) => ({
        class: [
          'font-sans leading-none',
          'm-0',
          'p-3',
          'rounded-md',
          'text-surface-600 dark:text-surface-200',
          'placeholder:text-surface-400 dark:placeholder:text-surface-500',
          'bg-surface-0 dark:bg-surface-900',
          'border border-surface-300 dark:border-surface-600',
          {
            'hover:border-primary-500 dark:hover:border-primary-400': !e.disabled,
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 dark:focus:ring-primary-400/50':
              !e.disabled,
            'opacity-60 select-none pointer-events-none cursor-default': e.disabled
          },
          'appearance-none',
          'transition-colors duration-200'
        ]
      })
    },
    // Inline Message
    inlinemessage: {
      root: ({ props: e }: any) => ({
        class: [
          'inline-flex items-center justify-center align-top gap-2',
          'py-2 m-0 rounded-md',
          //'ring-1 ring-inset ring-surface-200 dark:ring-surface-700 ring-offset-0',
          {
            'text-secondary-500 dark:text-secondary-300': e.severity == 'info',
            'text-green-500 dark:text-green-300': e.severity == 'success',
            'text-orange-500 dark:text-orange-300': e.severity == 'warn',
            'text-red-500 dark:text-red-300': e.severity == 'error'
          }
        ]
      }),
      icon: {
        class: ['w-4 h-4', 'shrink-0']
      },
      text: {
        class: ['text-sm leading-none', 'font-base first-letter:capitalize']
      }
    },
    // Toast
    toast: {
      root: ({ props: e }: any) => ({
        class: [
          'w-96 rounded-md',
          { '-translate-x-2/4': e.position == 'top-center' || e.position == 'bottom-center' }
        ]
      }),
      container: ({ props: e }: any) => ({
        class: [
          'my-4 rounded-lg w-full',
          'shadow-lg',
          'bg-surface-0 dark:bg-surface-800',
          {
            'text-blue-500 dark:text-blue-300': e.message.severity == 'info',
            'text-green-500 dark:text-green-300': e.message.severity == 'success',
            'text-orange-500 dark:text-orange-300': e.message.severity == 'warn',
            'text-red-500 dark:text-red-300': e.message.severity == 'error'
          }
        ]
      }),
      content: {
        class: 'flex items-start p-4'
      },
      icon: {
        class: ['w-5 h-5', 'mr-2 shrink-0']
      },
      text: {
        class: ['text-sm leading-none', 'ml-2', 'flex-1']
      },
      summary: {
        class: 'font-medium block'
      },
      detail: {
        class: 'mt-1.5 block text-surface-600 dark:text-surface-0/70'
      },
      closebutton: {
        class: [
          'flex items-center justify-center',
          'w-6 h-6',
          'ml-auto relative',
          'rounded-full',
          'bg-transparent',
          'text-surface-700 dark:text-surface-0/80',
          'transition duration-200 ease-in-out',
          'hover:bg-surface-100 dark:hover:bg-surface-700',
          'outline-none focus:ring-1 focus:ring-inset',
          'focus:ring-primary-500 dark:focus:ring-primary-400',
          'overflow-hidden'
        ]
      },
      closeicon: {
        class: ['w-3 h-3', 'shrink-0']
      },
      transition: {
        enterFromClass: 'opacity-0 translate-x-2/4',
        enterActiveClass: 'transition-[transform,opacity] duration-300',
        leaveFromClass: 'max-h-[1000px]',
        leaveActiveClass:
          '!transition-[max-height_.45s_cubic-bezier(0,1,0,1),opacity_.3s,margin-bottom_.3s] overflow-hidden',
        leaveToClass: 'max-h-0 opacity-0 mb-0'
      }
    },
    // Data Table
    datatable: {
      root: ({ props: e }: any) => ({
        class: [
          'relative',
          { 'flex flex-col': e.scrollable && e.scrollHeight === 'flex' },
          { 'h-full': e.scrollable && e.scrollHeight === 'flex' }
        ]
      }),
      loadingoverlay: {
        class: [
          'absolute',
          'top-8 left-0',
          'z-20',
          'flex items-center justify-center',
          'w-full h-full',
          'bg-surface-100/40 dark:bg-surface-800/40',
          'transition duration-200'
        ]
      },
      loadingicon: {
        class: 'w-8 h-8 animate-spin'
      },
      wrapper: ({ props: e }: any) => ({
        class: [
          {
            relative: e.scrollable,
            'flex flex-col grow': e.scrollable && e.scrollHeight === 'flex'
          },
          { 'h-full': e.scrollable && e.scrollHeight === 'flex' }
        ]
      }),
      header: ({ props: e }: any) => ({
        class: [
          'font-bold',
          e.showGridlines ? 'border-x border-t border-b-0' : 'border-y border-x-0',
          'p-4',
          'bg-surface-50 dark:bg-surface-800',
          'border-surface-200 dark:border-surface-700',
          'text-surface-700 dark:text-white/80'
        ]
      }),
      table: {
        class: 'w-full border-spacing-0 border-separate'
      },
      thead: ({ context: e }: any) => ({
        class: [{ 'bg-surface-50 top-0 z-40 sticky': e.scrollable }]
      }),
      tbody: ({ instance: e, context: r }: any) => ({
        class: [{ 'sticky z-20': e.frozenRow && r.scrollable }]
      }),
      tfoot: ({ context: e }: any) => ({ class: [{ 'bg-surface-50 bottom-0 z-0': e.scrollable }] }),
      footer: {
        class: [
          'font-bold',
          'border-t-0 border-b border-x-0',
          'p-4',
          'bg-surface-50 dark:bg-surface-800',
          'border-surface-200 dark:border-surface-700',
          'text-surface-700 dark:text-white/80'
        ]
      },
      column: {
        headercell: ({ context: e, props: r }: any) => ({
          class: [
            'font-bold',
            { 'sticky z-20 border-b': r.frozen || r.frozen === '' },
            { relative: e.resizable },
            'text-left',
            { 'first:border-l border-y border-r': e.showGridlines },
            'border-0 border-b border-solid',
            e.size === 'small' ? 'p-2' : e.size === 'large' ? 'p-5' : 'p-4',
            (r.sortable === '' || r.sortable) && e.sorted
              ? 'bg-primary-50 text-primary-700'
              : 'bg-surface-50 text-surface-700',
            (r.sortable === '' || r.sortable) && e.sorted
              ? 'dark:text-white dark:bg-primary-400/30'
              : 'dark:text-white/80 dark:bg-surface-800',
            'border-surface-200 dark:border-surface-700 ',
            {
              'hover:bg-surface-100 dark:hover:bg-surface-400/30':
                (r.sortable === '' || r.sortable) && !e.sorted
            },
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
            { 'transition duration-200': r.sortable === '' || r.sortable },
            { 'cursor-pointer': r.sortable === '' || r.sortable },
            { 'overflow-hidden space-nowrap border-y bg-clip-padding': e.resizable }
          ]
        }),
        headercontent: {
          class: 'flex items-center '
        },
        sort: ({ context: e }: any) => ({
          class: [
            e.sorted ? 'text-primary-500' : 'text-surface-700',
            e.sorted ? 'dark:text-primary-400' : 'dark:text-white/80'
          ]
        }),
        bodycell: ({ props: e, context: r, state: t, parent: o }: any) => ({
          class: [
            { 'sticky box-border border-b': o.instance.frozenRow },
            { 'sticky box-border border-b': e.frozen || e.frozen === '' },
            'text-left break-words',
            'border-0 border-b border-solid',
            { 'first:border-l border-r border-b': r == null ? void 0 : r.showGridlines },
            {
              'bg-surface-0 dark:bg-surface-800':
                o.instance.frozenRow || e.frozen || e.frozen === ''
            },
            { 'p-2': (r == null ? void 0 : r.size) === 'small' && !t.d_editing },
            { 'p-5': (r == null ? void 0 : r.size) === 'large' && !t.d_editing },
            {
              'p-4':
                (r == null ? void 0 : r.size) !== 'large' &&
                (r == null ? void 0 : r.size) !== 'small' &&
                !t.d_editing
            },
            { 'py-[0.6rem] px-2': t.d_editing },
            'border-surface-200 dark:border-surface-700 '
          ]
        }),
        footercell: ({ context: e }: any) => ({
          class: [
            'font-bold',
            'text-left',
            'border-0 border-b border-solid',
            { 'border-x border-y': e == null ? void 0 : e.showGridlines },
            (e == null ? void 0 : e.size) === 'small'
              ? 'p-2'
              : (e == null ? void 0 : e.size) === 'large'
                ? 'p-5'
                : 'p-4',
            'border-surface-200 dark:border-surface-700',
            'text-surface-700 dark:text-white/80',
            'bg-surface-50 dark:bg-surface-800'
          ]
        }),
        sorticon: ({ context: e }: any) => ({
          class: [
            'ml-2',
            e.sorted ? 'text-primary-700 dark:text-white/80' : 'text-surface-700 dark:text-white/70'
          ]
        }),
        sortbadge: {
          class: [
            'flex items-center justify-center align-middle',
            'rounded-full',
            'w-[1.143rem] leading-[1.143rem]',
            'ml-2',
            'text-primary-700 dark:text-white',
            'bg-primary-50 dark:bg-primary-400/30'
          ]
        },
        columnfilter: {
          class: 'inline-flex items-center ml-auto'
        },
        filteroverlay: {
          class: [
            'absolute top-0 left-0',
            'border-0 dark:border',
            'rounded-md',
            'shadow-md',
            'min-w-[12.5rem]',
            'bg-surface-0 dark:bg-surface-800',
            'text-surface-800 dark:text-white/80',
            'dark:border-surface-700'
          ]
        },
        filtermatchmodedropdown: {
          root: ({ state: e }: any) => ({
            class: [
              'flex',
              'relative',
              'my-2',
              'w-full',
              'rounded-md',
              'bg-surface-0 dark:bg-surface-900',
              'border border-surface-300 dark:border-surface-700',
              'text-surface-800 dark:text-white/80',
              'placeholder:text-surface-400 dark:placeholder:text-surface-500',
              'transition-all',
              'duration-200',
              'hover:border-primary-500 dark:hover:border-primary-300',
              {
                'outline-none outline-offset-0 ring ring-primary-400/50 dark:ring-primary-300/50':
                  e.focused
              },
              'cursor-pointer',
              'select-none'
            ]
          })
        },
        filterrowitems: {
          class: 'm-0 p-0 py-3 list-none'
        },
        filterrowitem: ({ context: e }: any) => ({
          class: [
            'font-normal',
            'leading-none',
            'relative',
            'border-0',
            'rounded-none',
            'm-0',
            'py-3 px-5',
            { 'text-surface-700 dark:text-white/80': !(e != null && e.highlighted) },
            {
              'bg-surface-0 dark:bg-surface-800 text-surface-700 dark:text-white/80': !(
                e != null && e.highlighted
              )
            },
            {
              'bg-primary-100 dark:bg-primary-400/40 text-primary-700 dark:text-white/80':
                e == null ? void 0 : e.highlighted
            },
            {
              'bg-primary-50 dark:bg-primary-400/40 text-primary-700 dark:text-white/80':
                e == null ? void 0 : e.highlighted
            },
            { 'hover:bg-surface-100 dark:hover:bg-surface-600/80': !(e != null && e.highlighted) },
            {
              'hover:text-surface-700 hover:bg-surface-100 dark:hover:text-white dark:hover:bg-surface-600/80':
                !(e != null && e.highlighted)
            },
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
            'transition-shadow',
            'duration-200',
            'cursor-pointer',
            'overflow-hidden',
            'whitespace-nowrap'
          ]
        }),
        filteroperator: {
          class: [
            'px-5 py-3',
            'border-b border-solid',
            'rounded-t-md',
            'text-surface-700 dark:text-white/80',
            'border-surface-200 dark:border-surface-800',
            'bg-surface-0 dark:bg-surface-700'
          ]
        },
        filteroperatordropdown: {
          root: ({ state: e }: any) => ({
            class: [
              'inline-flex',
              'relative',
              'w-full',
              'rounded-md',
              'bg-surface-0 dark:bg-surface-900',
              'border border-surface-300 dark:border-surface-700',
              'transition-all',
              'duration-200',
              'hover:border-primary-500 dark:hover:border-primary-300',
              {
                'outline-none outline-offset-0 ring ring-primary-400/50 dark:ring-primary-300/50':
                  e.focused
              },
              'cursor-pointer',
              'select-none'
            ]
          }),
          input: ({ props: e }: any) => ({
            class: [
              'font-sans',
              'leading-none',
              'block',
              'flex-auto',
              'bg-transparent',
              'border-0',
              {
                'text-surface-800 dark:text-white/80': e.modelValue,
                'text-surface-400 dark:text-surface-500': !e.modelValue
              },
              'placeholder:text-surface-400 dark:placeholder:text-surface-500',
              'w-[1%]',
              'p-3',
              'rounded-none',
              'transition',
              'duration-200',
              'focus:outline-none focus:shadow-none',
              'relative',
              'cursor-pointer',
              'overflow-hidden overflow-ellipsis',
              'whitespace-nowrap',
              'appearance-none'
            ]
          }),
          trigger: {
            class: [
              'flex items-center justify-center',
              'shrink-0',
              'bg-transparent',
              'text-surface-500',
              'w-12',
              'rounded-tr-md',
              'rounded-br-md'
            ]
          },
          panel: {
            class: [
              'absolute top-0 left-0',
              'border-0 dark:border',
              'rounded-md',
              'shadow-md',
              'bg-surface-0 dark:bg-surface-800',
              'text-surface-800 dark:text-white/80',
              'dark:border-surface-700'
            ]
          },
          item: ({ context: e }: any) => ({
            class: [
              'font-normal',
              'leading-none',
              'relative',
              'border-0',
              'rounded-none',
              'm-0',
              'py-3 px-5',
              { 'text-surface-700 dark:text-white/80': !e.focused && !e.selected },
              {
                'bg-surface-50 dark:bg-surface-600/60 text-surface-700 dark:text-white/80':
                  e.focused && !e.selected
              },
              {
                'bg-primary-100 dark:bg-primary-400/40 text-primary-700 dark:text-white/80':
                  e.focused && e.selected
              },
              {
                'bg-primary-50 dark:bg-primary-400/40 text-primary-700 dark:text-white/80':
                  !e.focused && e.selected
              },
              { 'hover:bg-surface-100 dark:hover:bg-surface-600/80': !e.focused && !e.selected },
              {
                'hover:text-surface-700 hover:bg-surface-100 dark:hover:text-white dark:hover:bg-surface-600/80':
                  e.focused && !e.selected
              },
              'transition-shadow',
              'duration-200',
              'cursor-pointer',
              'overflow-hidden',
              'whitespace-nowrap'
            ]
          })
        },
        filterconstraint: {
          class: [
            'py-3 px-5',
            'border-b border-solid',
            'border-surface-200 dark:border-surface-700'
          ]
        },
        filteraddrule: {
          class: 'py-3 px-5'
        },
        filteraddrulebutton: {
          root: {
            class: [
              'relative',
              'items-center inline-flex text-center align-bottom justify-center',
              'text-sm py-2 px-3 w-full',
              'rounded-md',
              'bg-transparent border-transparent',
              'text-primary-500 dark:text-primary-400',
              'hover:bg-primary-300/20',
              'transition duration-200 ease-in-out',
              'cursor-pointer overflow-hidden select-none'
            ]
          },
          label: {
            class: 'flex-auto grow-0'
          },
          icon: {
            class: 'mr-2'
          }
        },
        filterremovebutton: {
          root: {
            class: [
              'relative',
              'items-center inline-flex text-center align-bottom justify-center',
              'text-sm py-2 px-3 w-full mt-2',
              'rounded-md',
              'bg-transparent border-transparent',
              'text-red-500 dark:text-red-400',
              'hover:bg-red-300/20',
              'transition duration-200 ease-in-out',
              'cursor-pointer overflow-hidden select-none'
            ]
          },
          label: {
            class: 'flex-auto grow-0'
          },
          icon: {
            class: 'mr-2'
          }
        },
        filterbuttonbar: {
          class: ['flex items-center justify-between', 'py-3 px-5']
        },
        filterclearbutton: {
          root: {
            class: [
              'relative',
              'items-center inline-flex text-center align-bottom justify-center',
              'text-sm py-2 px-3',
              'rounded-md',
              'text-primary-500 border border-primary-500',
              'hover:bg-primary-300/20',
              'transition duration-200 ease-in-out',
              'cursor-pointer overflow-hidden select-none'
            ]
          }
        },
        filterapplybutton: {
          root: {
            class: [
              'relative',
              'items-center inline-flex text-center align-bottom justify-center',
              'text-sm py-2 px-3',
              'rounded-md',
              'text-white dark:text-surface-900',
              'bg-primary-500 dark:bg-primary-400',
              'hover:bg-primary-600 dark:hover:bg-primary-300 hover:border-primary-600 dark:hover:border-primary-300',
              'transition duration-200 ease-in-out',
              'cursor-pointer overflow-hidden select-none'
            ]
          }
        },
        filtermenubutton: ({ context: e }: any) => ({
          class: [
            'relative',
            'inline-flex items-center justify-center',
            'w-8 h-8',
            'ml-2',
            'rounded-full',
            { 'bg-primary-50 text-primary-700': e.active },
            'dark:text-white/70 dark:hover:text-white/80 dark:bg-surface-800',
            'hover:text-surface-700 hover:bg-surface-300/20',
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
            'transition duration-200',
            'cursor-pointer no-underline overflow-hidden'
          ]
        }),
        headerfilterclearbutton: ({ context: e }: any) => ({
          class: [
            'relative',
            'inline-flex items-center justify-center',
            'text-left',
            'border-none',
            'm-0 p-0 ml-2',
            'bg-transparent',
            'cursor-pointer no-underline overflow-hidden select-none',
            { invisible: !e.hidden }
          ]
        }),
        rowtoggler: {
          class: [
            'relative',
            'inline-flex items-center justify-center',
            'text-left',
            'm-0 p-0',
            'w-8 h-8',
            'border-0 rounded-full',
            'text-surface-500 dark:text-white/70',
            'bg-transparent',
            'focus-visible:outline-none focus-visible:outline-offset-0',
            'focus-visible:ring focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
            'transition duration-200',
            'overflow-hidden',
            'cursor-pointer select-none'
          ]
        },
        columnresizer: {
          class: [
            'block',
            'absolute top-0 right-0',
            'w-2 h-full',
            'm-0 p-0',
            'border border-transparent',
            'cursor-col-resize'
          ]
        },
        rowreordericon: {
          class: 'cursor-move'
        },
        roweditorinitbutton: {
          class: [
            'relative',
            'inline-flex items-center justify-center',
            'text-left',
            'w-8 h-8',
            'border-0 rounded-full',
            'text-surface-700 dark:text-white/70',
            'border-transparent',
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
            'hover:text-surface-700 hover:bg-surface-300/20',
            'transition duration-200',
            'overflow-hidden',
            'cursor-pointer select-none'
          ]
        },
        roweditorsavebutton: {
          class: [
            'relative',
            'inline-flex items-center justify-center',
            'text-left',
            'w-8 h-8',
            'border-0 rounded-full',
            'text-surface-700 dark:text-white/70',
            'border-transparent',
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
            'hover:text-surface-700 hover:bg-surface-300/20',
            'transition duration-200',
            'overflow-hidden',
            'cursor-pointer select-none'
          ]
        },
        roweditorcancelbutton: {
          class: [
            'relative',
            'inline-flex items-center justify-center',
            'text-left',
            'w-8 h-8',
            'border-0 rounded-full',
            'text-surface-700 dark:text-white/70',
            'border-transparent',
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
            'hover:text-surface-700 hover:bg-surface-300/20',
            'transition duration-200',
            'overflow-hidden',
            'cursor-pointer select-none'
          ]
        },
        rowRadiobutton: {
          root: {
            class: [
              'relative',
              'inline-flex',
              'align-bottom',
              'w-[1.571rem] h-[1.571rem]',
              'cursor-pointer',
              'select-none'
            ]
          },
          box: ({ props: e }: any) => ({
            class: [
              'flex justify-center items-center',
              'w-[1.571rem] h-[1.571rem]',
              'border-2',
              'rounded-full',
              'transition duration-200 ease-in-out',
              {
                'text-surface-700 dark:text-white/80': !e.modelValue,
                'bg-surface-0 dark:bg-surface-900': !e.modelValue,
                'border-surface-300 dark:border-surface-700': !e.modelValue,
                'border-primary-500 dark:border-primary-400': e.modelValue,
                'bg-primary-500 dark:bg-primary-400': e.modelValue
              },
              {
                'peer-hover:border-primary-500 dark:peer-hover:border-primary-400': !e.disabled,
                'peer-hover:border-primary-600 dark:peer-hover:border-primary-300 peer-hover:bg-primary-600 dark:peer-hover:bg-primary-300':
                  !e.disabled && e.modelValue,
                'peer-focus-visible:border-primary-500 dark:peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20 dark:peer-focus-visible:ring-primary-300/20':
                  !e.disabled,
                'opacity-60 cursor-default': e.disabled
              }
            ]
          }),
          input: {
            class: [
              'peer',
              'w-full ',
              'h-full',
              'absolute',
              'top-0 left-0',
              'z-10',
              'p-0',
              'm-0',
              'opacity-0',
              'rounded-md',
              'outline-none',
              'border-2 border-surface-200 dark:border-surface-700',
              'appareance-none',
              'cursor-pointer'
            ]
          },
          icon: ({ props: e }: any) => ({
            class: [
              'block',
              'rounded-full',
              'w-[0.857rem] h-[0.857rem]',
              'bg-surface-0 dark:bg-surface-900',
              {
                'backface-hidden scale-10 invisible': !e.modelValue,
                'transform visible scale-[1.1]': e.modelValue
              },
              'transition duration-200'
            ]
          })
        },
        headercheckbox: {
          root: {
            class: [
              'relative',
              'inline-flex',
              'align-bottom',
              'w-6',
              'h-6',
              'cursor-pointer',
              'select-none'
            ]
          },
          box: ({ props: e, context: r }: any) => ({
            class: [
              'flex',
              'items-center',
              'justify-center',
              'w-6',
              'h-6',
              'rounded-md',
              'border-2',
              {
                'border-surface-200 bg-surface-0 dark:border-surface-700 dark:bg-surface-900':
                  !r.checked,
                'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400':
                  r.checked
              },
              {
                'peer-hover:border-primary-500 dark:peer-hover:border-primary-400':
                  !e.disabled && !r.checked,
                'peer-hover:bg-primary-600 dark:peer-hover:bg-primary-300 peer-hover:border-primary-700 dark:peer-hover:border-primary-300':
                  !e.disabled && r.checked,
                'peer-focus-visible:border-primary-500 dark:peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20 dark:peer-focus-visible:ring-primary-300/20':
                  !e.disabled,
                'cursor-default opacity-60': e.disabled
              },
              'transition-colors',
              'duration-200'
            ]
          }),
          input: {
            class: [
              'peer',
              'w-full ',
              'h-full',
              'absolute',
              'top-0 left-0',
              'z-10',
              'p-0',
              'm-0',
              'opacity-0',
              'rounded-md',
              'outline-none',
              'border-2 border-surface-200 dark:border-surface-700',
              'appareance-none',
              'cursor-pointer'
            ]
          },
          icon: {
            class: [
              'text-base leading-none',
              'w-4',
              'h-4',
              'text-white dark:text-surface-900',
              'transition-all',
              'duration-200'
            ]
          }
        },
        rowCheckbox: {
          root: {
            class: [
              'relative',
              'inline-flex',
              'align-bottom',
              'w-6',
              'h-6',
              'cursor-pointer',
              'select-none'
            ]
          },
          box: ({ props: e, context: r }: any) => ({
            class: [
              'flex',
              'items-center',
              'justify-center',
              'w-6',
              'h-6',
              'rounded-md',
              'border-2',
              {
                'border-surface-200 bg-surface-0 dark:border-surface-700 dark:bg-surface-900':
                  !r.checked,
                'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400':
                  r.checked
              },
              {
                'peer-hover:border-primary-500 dark:peer-hover:border-primary-400':
                  !e.disabled && !r.checked,
                'peer-hover:bg-primary-600 dark:peer-hover:bg-primary-300 peer-hover:border-primary-700 dark:peer-hover:border-primary-300':
                  !e.disabled && r.checked,
                'peer-focus-visible:border-primary-500 dark:peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20 dark:peer-focus-visible:ring-primary-300/20':
                  !e.disabled,
                'cursor-default opacity-60': e.disabled
              },
              'transition-colors',
              'duration-200'
            ]
          }),
          input: {
            class: [
              'peer',
              'w-full ',
              'h-full',
              'absolute',
              'top-0 left-0',
              'z-10',
              'p-0',
              'm-0',
              'opacity-0',
              'rounded-md',
              'outline-none',
              'border-2 border-surface-200 dark:border-surface-700',
              'appareance-none',
              'cursor-pointer'
            ]
          },
          icon: {
            class: [
              'text-base leading-none',
              'w-4',
              'h-4',
              'text-white dark:text-surface-900',
              'transition-all',
              'duration-200'
            ]
          }
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0'
        }
      },
      bodyrow: ({ context: e, props: r }: any) => ({
        // Wind theme bodyrow.
        class: [
          'dark:text-white/80',
          { 'bg-surface-100 dark:bg-surface-500/30': e.selected && e.stripedRows },
          { 'bg-surface-50 dark:bg-surface-500/30': e.selected && !e.stripedRows },
          { 'bg-surface-0 text-surface-600 dark:bg-surface-800': !e.selected },
          { 'bg-surface-0 dark:bg-surface-800': r.frozenRow },
          {
            'odd:bg-surface-0 odd:text-surface-600 dark:odd:bg-surface-800 even:bg-surface-50 even:text-surface-600 dark:even:bg-surface-900/60':
              e.stripedRows && !e.selected
          },
          {
            'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-500 ring-inset dark:focus:ring-primary-400':
              r.selectionMode
          },
          { 'hover:bg-surface-300/20 hover:text-surface-600': r.selectionMode && !e.selected },
          { 'transition duration-200': (r.selectionMode && !e.selected) || r.rowHover },
          { 'cursor-pointer': r.selectionMode }
        ]
      }),
      rowexpansion: {
        class: 'bg-surface-0 dark:bg-surface-800 text-surface-600 dark:text-white/80'
      },
      rowgroupheader: {
        class: [
          'sticky z-20',
          'bg-surface-0 text-surface-600 dark:text-white/70',
          'dark:bg-surface-800'
        ]
      },
      rowgroupfooter: {
        class: [
          'sticky z-20',
          'bg-surface-0 text-surface-600 dark:text-white/70',
          'dark:bg-surface-800'
        ]
      },
      rowgrouptoggler: {
        class: [
          'relative',
          'inline-flex items-center justify-center',
          'text-left',
          'm-0 p-0',
          'w-8 h-8',
          'border-0 rounded-full',
          'text-surface-500 dark:text-white/70',
          'bg-transparent',
          'focus-visible:outline-none focus-visible:outline-offset-0',
          'focus-visible:ring focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
          'transition duration-200',
          'overflow-hidden',
          'cursor-pointer select-none'
        ]
      },
      rowgrouptogglericon: {
        class: 'inline-block w-4 h-4'
      },
      resizehelper: {
        class: 'absolute hidden w-[2px] z-20 bg-primary-500 dark:bg-primary-400'
      }
    },
    // Pagination
    paginator: {
      root: {
        class: [
          'flex items-center justify-center flex-wrap',
          'px-4',
          'border-t',
          'bg-surface-0 dark:bg-surface-800',
          'text-surface-500 dark:text-white/60',
          'border-surface-200 dark:border-surface-700'
        ]
      },
      paginatorwrapper: {
        class: 'mt-[-1px]'
      },
      firstpagebutton: ({ context: e }: any) => ({
        class: [
          'relative',
          'text-sm font-medium',
          'inline-flex items-center justify-center',
          'border-t-2 border-transparent',
          'min-w-[3rem] h-12 mt-[-1px]',
          'text-surface-500 dark:text-white/60',
          {
            'hover:border-surface-300 dark:hover:border-surface-200/30': !e.disabled,
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400':
              !e.disabled
          },
          'transition duration-200',
          'user-none overflow-hidden',
          { 'cursor-default pointer-events-none opacity-60': e.disabled }
        ]
      }),
      previouspagebutton: ({ context: e }: any) => ({
        class: [
          'relative',
          'text-sm font-medium',
          'inline-flex items-center justify-center',
          'border-t-2 border-transparent',
          'min-w-[3rem] h-12 mt-[-1px]',
          'text-surface-500 dark:text-white/60',
          {
            'hover:border-surface-300 dark:hover:border-surface-200/30': !e.disabled,
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400':
              !e.disabled
          },
          'transition duration-200',
          'user-none overflow-hidden',
          { 'cursor-default pointer-events-none opacity-60': e.disabled }
        ]
      }),
      nextpagebutton: ({ context: e }: any) => ({
        class: [
          'relative',
          'text-sm font-medium',
          'inline-flex items-center justify-center',
          'border-t-2 border-transparent',
          'min-w-[3rem] h-12 mt-[-1px]',
          'text-surface-500 dark:text-white/60',
          {
            'hover:border-surface-300 dark:hover:border-surface-200/30': !e.disabled,
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400':
              !e.disabled
          },
          'transition duration-200',
          'user-none overflow-hidden',
          { 'cursor-default pointer-events-none opacity-60': e.disabled }
        ]
      }),
      lastpagebutton: ({ context: e }: any) => ({
        class: [
          'relative',
          'text-sm font-medium',
          'inline-flex items-center justify-center',
          'border-t-2 border-transparent',
          'min-w-[3rem] h-12 mt-[-1px]',
          'text-surface-500 dark:text-white/60',
          {
            'hover:border-surface-300 dark:hover:border-surface-200/30': !e.disabled,
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400':
              !e.disabled
          },
          'transition duration-200',
          'user-none overflow-hidden',
          { 'cursor-default pointer-events-none opacity-60': e.disabled }
        ]
      }),
      pagebutton: ({ context: e }: any) => ({
        class: [
          'relative',
          'text-sm font-medium',
          'inline-flex items-center justify-center',
          'border-t-2',
          'min-w-[3rem] h-12 mt-[-1px]',
          {
            'text-surface-500 dark:text-white/60 border-transparent': !e.active,
            'border-primary-500 dark:border-primary-400 text-primary-500 dark:text-surface-0':
              e.active
          },
          {
            'hover:border-surface-300 dark:hover:border-surface-200/30': !e.disabled && !e.active,
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400':
              !e.disabled
          },
          'transition duration-200',
          'user-none overflow-hidden',
          { 'cursor-default pointer-events-none opacity-60': e.disabled }
        ]
      }),
      rowperpagedropdown: {
        root: ({ props: e, state: r }: any) => ({
          class: [
            'inline-flex',
            'relative',
            'h-8',
            'rounded-md',
            'mx-2',
            'bg-transparent',
            'transition-all',
            'duration-200',
            {
              'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-600 dark:focus-visible:ring-primary-500':
                !r.focused
            },
            'cursor-pointer',
            'select-none',
            {
              'opacity-60': e.disabled,
              'pointer-events-none': e.disabled,
              'cursor-default': e.disabled
            }
          ]
        }),
        input: {
          class: [
            'font-sans',
            'leading-6',
            'sm:text-sm',
            'block',
            'flex-auto',
            'flex items-center',
            'bg-transparent',
            'border-0',
            'text-surface-800 dark:text-white/80',
            'w-[1%]',
            'py-1.5 px-3 pr-0',
            'rounded-none',
            'transition',
            'duration-200',
            'focus-visible:outline-none focus-visible:shadow-none',
            'relative',
            'cursor-pointer',
            'overflow-hidden overflow-ellipsis',
            'whitespace-nowrap',
            'appearance-none'
          ]
        },
        trigger: {
          class: [
            'sm:text-sm',
            'flex items-center justify-center',
            'shrink-0',
            'bg-transparent',
            'text-surface-500',
            'w-12',
            'h-8',
            'rounded-tr-md',
            'rounded-br-md'
          ]
        },
        dropdownicon: {
          class: ''
        },
        panel: {
          class: [
            'absolute top-0 left-0',
            'mt-1',
            'border-0',
            'rounded-md',
            'shadow-md',
            'bg-surface-0 dark:bg-surface-800',
            'text-surface-800 dark:text-white/80',
            'ring-1 ring-inset ring-surface-300 dark:ring-surface-700'
          ]
        },
        wrapper: {
          class: ['max-h-[15rem]', 'overflow-auto']
        },
        list: {
          class: 'py-1 list-none m-0'
        },
        item: ({ context: e }: any) => ({
          class: [
            'sm:text-sm',
            'leading-none',
            { 'font-normal': !e.selected, 'font-bold': e.selected },
            'relative',
            'border-0',
            'rounded-none',
            'm-0',
            'py-2 px-4',
            { 'text-surface-700 dark:text-white/80': !e.focused && !e.selected },
            {
              'bg-primary-500 dark:bg-primary-400 text-white dark:text-surface-700':
                e.focused && e.selected
            },
            { 'bg-transparent text-surface-700 dark:text-white/80': !e.focused && e.selected },
            'hover:bg-primary-500 dark:hover:bg-primary-400 hover:text-white dark:hover:text-surface-700',
            'cursor-pointer',
            'overflow-hidden',
            'whitespace-nowrap'
          ]
        })
      },
      jumptopageinput: {
        root: {
          class: 'inline-flex mx-2'
        },
        input: {
          root: {
            class: [
              'relative',
              'font-sans',
              'leading-6',
              'sm:text-sm',
              'block',
              'flex-auto',
              'bg-transparent',
              'border-0',
              'text-surface-800 dark:text-white/80',
              'ring-1 ring-inset ring-surface-300 dark:ring-surface-700',
              'w-[1%] max-w-[3rem]',
              'py-1.5 px-3',
              'rounded-md',
              'transition',
              'duration-200',
              'hover:border-primary-500 dark:hover:border-primary-400',
              'focus-visible:outline-none focus-visible:shadow-none',
              'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-600 dark:focus-visible:ring-primary-500',
              'cursor-pointer',
              'overflow-hidden overflow-ellipsis',
              'whitespace-nowrap',
              'appearance-none'
            ]
          }
        }
      },
      jumptopagedropdown: {
        root: ({ props: e, state: r }: any) => ({
          class: [
            'inline-flex',
            'relative',
            'h-8',
            'rounded-md',
            'mx-2',
            'bg-transparent',
            'transition-all',
            'duration-200',
            {
              'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-600 dark:focus-visible:ring-primary-500':
                !r.focused
            },
            'cursor-pointer',
            'select-none',
            {
              'opacity-60': e.disabled,
              'pointer-events-none': e.disabled,
              'cursor-default': e.disabled
            }
          ]
        }),
        input: {
          class: [
            'font-sans',
            'leading-6',
            'sm:text-sm',
            'block',
            'flex-auto',
            'flex items-center',
            'bg-transparent',
            'border-0',
            'text-surface-800 dark:text-white/80',
            'w-[1%]',
            'py-1.5 px-3 pr-0',
            'rounded-none',
            'transition',
            'duration-200',
            'focus-visible:outline-none focus-visible:shadow-none',
            'relative',
            'cursor-pointer',
            'overflow-hidden overflow-ellipsis',
            'whitespace-nowrap',
            'appearance-none'
          ]
        },
        trigger: {
          class: [
            'sm:text-sm',
            'flex items-center justify-center',
            'shrink-0',
            'bg-transparent',
            'text-surface-500',
            'w-12',
            'h-8',
            'rounded-tr-md',
            'rounded-br-md'
          ]
        },
        panel: {
          class: [
            'absolute top-0 left-0',
            'mt-1',
            'border-0',
            'rounded-md',
            'shadow-md',
            'bg-surface-0 dark:bg-surface-800',
            'text-surface-800 dark:text-white/80',
            'ring-1 ring-inset ring-surface-300 dark:ring-surface-700'
          ]
        },
        wrapper: {
          class: ['max-h-[15rem]', 'overflow-auto']
        },
        list: {
          class: 'py-1 list-none m-0'
        },
        item: ({ context: e }: any) => ({
          class: [
            'sm:text-sm',
            'leading-none',
            { 'font-normal': !e.selected, 'font-bold': e.selected },
            'relative',
            'border-0',
            'rounded-none',
            'm-0',
            'py-2 px-4',
            { 'text-surface-700 dark:text-white/80': !e.focused && !e.selected },
            {
              'bg-primary-500 dark:bg-primary-400 text-white dark:text-surface-700':
                e.focused && e.selected
            },
            { 'bg-transparent text-surface-700 dark:text-white/80': !e.focused && e.selected },
            'hover:bg-primary-500 dark:hover:bg-primary-400 hover:text-white dark:hover:text-surface-700',
            'cursor-pointer',
            'overflow-hidden',
            'whitespace-nowrap'
          ]
        })
      }
    },
    // SideBar
    sidebar: {
      root: ({ props: e }: any) => ({
        class: [
          'flex flex-col',
          'relative',
          {
            '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
              e.position == 'full'
          },
          {
            'h-full w-80': e.position == 'left' || e.position == 'right',
            'h-auto w-full': e.position == 'top' || e.position == 'bottom'
          },
          'border-0 dark:border',
          'shadow-lg',
          'bg-surface-0 dark:bg-surface-800',
          'text-surface-700 dark:text-white/80',
          'dark:border-surface-700',
          'transition-transform',
          'duration-300',
          'pointer-events-auto'
        ]
      }),
      header: {
        class: [
          'flex items-center justify-between',
          'shrink-0',
          'p-5',
          'bg-surface-0 dark:bg-surface-800',
          'text-surface-700 dark:text-surface-0/80'
        ]
      },
      title: {
        class: ['font-bold text-lg']
      },
      icons: {
        class: ['flex items-center']
      },
      closeButton: {
        class: [
          'relative',
          'flex items-center justify-center',
          'mr-2',
          'last:mr-0',
          'w-8 h-8',
          'border-0',
          'rounded-full',
          'text-surface-500',
          'bg-transparent',
          'transition duration-200 ease-in-out',
          'hover:text-surface-700 dark:hover:text-white/80',
          'hover:bg-surface-100 dark:hover:bg-surface-800/80',
          'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset',
          'focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
          'overflow-hidden'
        ]
      },
      closeButtonIcon: {
        class: ['inline-block', 'w-4', 'h-4']
      },
      content: {
        class: ['pt-2', 'px-5', 'h-full', 'w-full', 'grow', 'overflow-y-auto']
      },
      mask: ({ props: e }: any) => ({
        class: [
          'transition-all',
          'duration-300',
          //{ 'p-5': !e.position == 'full' },
          {
            'has-[.mask-active]:bg-transparent bg-black/40': e.modal,
            'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': e.modal
          }
        ]
      }),
      transition: ({ props: e }: any) =>
        e.position === 'top'
          ? {
              enterFromClass: 'translate-x-0 -translate-y-full translate-z-0 mask-active',
              leaveToClass: 'translate-x-0 -translate-y-full translate-z-0 mask-active'
            }
          : e.position === 'bottom'
            ? {
                enterFromClass: 'translate-x-0 translate-y-full translate-z-0 mask-active',
                leaveToClass: 'translate-x-0 translate-y-full translate-z-0 mask-active'
              }
            : e.position === 'left'
              ? {
                  enterFromClass: '-translate-x-full translate-y-0 translate-z-0 mask-active',
                  leaveToClass: '-translate-x-full translate-y-0 translate-z-0 mask-active'
                }
              : e.position === 'right'
                ? {
                    enterFromClass: 'translate-x-full translate-y-0 translate-z-0 mask-active',
                    leaveToClass: 'translate-x-full translate-y-0 translate-z-0 mask-active'
                  }
                : {
                    enterFromClass: 'opacity-0 mask-active',
                    enterActiveClass: 'transition-opacity duration-400 ease-in',
                    leaveActiveClass: 'transition-opacity duration-400 ease-in',
                    leaveToClass: 'opacity-0 mask-active'
                  }
    },
    // Drop Down
    dropdown: {
      root: ({ props: e, state: r }: any) => ({
        class: [
          'inline-flex',
          'relative',
          'w-full',
          'rounded-md',
          'shadow-sm',
          'bg-surface-0 dark:bg-surface-900',
          {
            'ring-1 ring-inset ring-surface-300 dark:ring-surface-700': !r.focused,
            'ring-2 ring-inset ring-primary-500 dark:ring-primary-400': r.focused
          },
          'cursor-default',
          'select-none',
          { 'opacity-60': e.disabled, 'pointer-events-none': e.disabled }
        ]
      }),
      input: ({ props: e }: any) => ({
        class: [
          'font-sans',
          'leading-6',
          'sm:text-sm',
          'block',
          'flex-auto',
          'bg-transparent',
          'border-0',
          {
            'text-surface-800 dark:text-white/80': e.modelValue != null,
            'text-surface-400 dark:text-surface-500': e.modelValue == null
          },
          'placeholder:text-surface-400 dark:placeholder:text-surface-500',
          'w-[1%]',
          'py-1.5 px-3',
          { 'pr-7': e.showClear },
          'rounded-none',
          'transition',
          'duration-200',
          'focus:outline-none focus:shadow-none',
          'relative',
          'cursor-pointer',
          'overflow-hidden overflow-ellipsis',
          'whitespace-nowrap',
          'appearance-none'
        ]
      }),
      trigger: {
        class: [
          'sm:text-sm',
          'flex items-center justify-center',
          'shrink-0',
          'bg-transparent',
          'text-surface-500',
          'w-12',
          'rounded-tr-md',
          'rounded-br-md'
        ]
      },
      panel: {
        class: [
          'absolute top-0 left-0',
          'mt-2',
          'border-0',
          'rounded-md',
          'shadow-md',
          'bg-surface-0 dark:bg-surface-800',
          'text-surface-800 dark:text-white/80',
          'ring-1 ring-inset ring-surface-300 dark:ring-surface-700'
        ]
      },
      wrapper: {
        class: ['max-h-[15rem]', 'overflow-auto']
      },
      list: {
        class: 'py-1 list-none m-0'
      },
      item: ({ context: e }: any) => ({
        class: [
          'sm:text-sm',
          'leading-none',
          { 'font-normal': !e.selected, 'font-bold': e.selected },
          'relative',
          'border-0',
          'rounded-none',
          'm-0',
          'py-2 px-4',
          { 'text-surface-700 dark:text-white/80': !e.focused && !e.selected && !e.disabled },
          { 'text-surface-600 dark:text-white/70': !e.focused && !e.selected && e.disabled },
          {
            'bg-surface-200 dark:bg-surface-600/60 text-surface-700 dark:text-white/80':
              e.focused && !e.selected
          },
          {
            'bg-primary-500 dark:bg-primary-400 text-white dark:text-surface-700':
              e.focused && e.selected
          },
          { 'bg-transparent text-surface-700 dark:text-white/80': !e.focused && e.selected },
          'hover:bg-primary-500 dark:hover:bg-primary-400 hover:text-white dark:hover:text-surface-700',
          'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
          { 'pointer-events-none cursor-default': e.disabled },
          { 'cursor-pointer': !e.disabled },
          'overflow-hidden',
          'whitespace-nowrap'
        ]
      }),
      itemgroup: {
        class: [
          'font-bold',
          'sm:text-sm',
          'm-0',
          'py-2 px-4',
          'text-surface-800 dark:text-white/80',
          'bg-surface-0 dark:bg-surface-600/80',
          'cursor-auto'
        ]
      },
      emptymessage: {
        class: [
          'leading-none',
          'sm:text-sm',
          'py-2 px-4',
          'text-surface-800 dark:text-white/80',
          'bg-transparent'
        ]
      },
      header: {
        class: [
          'p-0',
          'm-0',
          'rounded-tl-md',
          'rounded-tr-md',
          'text-surface-700 dark:text-white/80',
          'bg-surface-100 dark:bg-surface-800'
        ]
      },
      filtercontainer: {
        class: 'relative'
      },
      filterinput: {
        class: [
          'font-sans',
          'leading-none',
          'sm:text-sm',
          'py-1.5 px-3',
          'pr-7',
          '-mr-7',
          'w-full',
          'text-surface-700 dark:text-white/80',
          'bg-surface-0 dark:bg-surface-900',
          'placeholder:text-surface-400',
          'ring-1 ring-inset ring-surface-300 dark:ring-surface-700',
          'border-0',
          'rounded-tl-md',
          'rounded-tr-md',
          'appearance-none',
          'focus:ring-2 focus:ring-inset focus:outline-none focus:outline-offset-0',
          'focus:ring-primary-600 dark:focus:ring-primary-500',
          'appearance-none'
        ]
      },
      filtericon: {
        class: ['absolute', 'top-1/2 right-3', '-mt-2']
      },
      clearicon: {
        class: ['text-surface-500', 'absolute', 'top-1/2', 'right-12', '-mt-2']
      },
      transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass:
          'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0'
      }
    },
    // Tab View & Tab Panel
    tabview: {
      navContainer: ({ props: e }: any) => ({
        class: ['relative', { 'overflow-hidden': e.scrollable }]
      }),
      navContent: {
        class: [
          'overflow-y-hidden overscroll-contain',
          'overscroll-auto',
          'scroll-smooth',
          '[&::-webkit-scrollbar]:hidden'
        ]
      },
      previousButton: {
        class: [
          'flex items-center justify-center',
          '!absolute',
          'top-0 left-0',
          'z-20',
          'h-full w-12',
          'rounded-none',
          'bg-surface-0 dark:bg-surface-800',
          'text-primary-500 dark:text-primary-400',
          'shadow-md'
        ]
      },
      nextButton: {
        class: [
          'flex items-center justify-center',
          '!absolute',
          'top-0 right-0',
          'z-20',
          'h-full w-12',
          'rounded-none',
          'bg-surface-0 dark:bg-surface-800',
          'text-primary-500 dark:text-primary-400',
          'shadow-md'
        ]
      },
      nav: {
        class: [
          'flex flex-1',
          'list-none',
          'p-0 m-0',
          'bg-surface-0 dark:bg-surface-800',
          'border-b-2 border-surface-200 dark:border-surface-700',
          'text-surface-900 dark:text-surface-0/80'
        ]
      },
      tabpanel: {
        header: ({ props: e }: any) => ({
          class: [
            'mr-0',
            {
              'opacity-60 cursor-default user-select-none select-none pointer-events-none':
                e == null ? void 0 : e.disabled
            }
          ]
        }),
        headerAction: ({ parent: e, context: r }: any) => ({
          class: [
            'relative',
            'font-bold',
            'flex items-center',
            'p-5',
            '-mb-[2px]',
            'border-b-2',
            'rounded-t-md',
            {
              'border-surface-200 dark:border-surface-700': e.state.d_activeIndex !== r.index,
              'bg-surface-0 dark:bg-surface-800': e.state.d_activeIndex !== r.index,
              'text-surface-700 dark:text-surface-0/80': e.state.d_activeIndex !== r.index,
              'border-primary-500 dark:border-primary-400': e.state.d_activeIndex === r.index,
              'text-font-main dark:text-primary-400': e.state.d_activeIndex === r.index
            },
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset',
            'focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
            {
              'hover:bg-surface-0 dark:hover:bg-surface-800/80': e.state.d_activeIndex !== r.index,
              'hover:border-surface-400 dark:hover:border-primary-400':
                e.state.d_activeIndex !== r.index,
              'hover:text-surface-900 dark:hover:text-surface-0': e.state.d_activeIndex !== r.index
            },
            'transition-all duration-200',
            'cursor-pointer select-none text-decoration-none',
            'overflow-hidden',
            'user-select-none'
          ]
        }),
        headerTitle: {
          class: ['leading-none', 'whitespace-nowrap']
        },
        content: {
          class: [
            'p-2 lg:p-5',
            'rounded-b-md',
            'bg-surface-0 dark:bg-surface-800',
            'text-surface-700 dark:text-surface-0/80',
            'border-0'
          ]
        }
      }
    },
    // Confirm Dialog
    dialog: {
      root: ({ state: e }: any) => ({
        class: [
          'rounded-lg',
          'shadow-xl',
          'border-0',
          'max-h-[90vh]',
          { 'sm:w-full sm:max-w-lg': !e.maximized },
          'm-0',
          'transform',
          'scale-100',
          'dark:border',
          'dark:border-surface-700',
          {
            'transition-none': e.maximized,
            'transform-none': e.maximized,
            '!w-screen': e.maximized,
            '!h-screen': e.maximized,
            '!max-h-full': e.maximized,
            '!top-0': e.maximized,
            '!left-0': e.maximized
          }
        ]
      }),
      header: {
        class: [
          'flex items-center justify-between',
          'shrink-0',
          'p-6 pt-4',
          'rounded-tl-lg',
          'rounded-tr-lg',
          'bg-surface-0 dark:bg-surface-800',
          'text-surface-700 dark:text-surface-0/80'
        ]
      },
      title: {
        class: ['font-semibold text-base leading-6']
      },
      icons: {
        class: ['flex items-center']
      },
      closeButton: {
        class: [
          'relative',
          'flex items-center justify-center',
          'mr-2',
          'last:mr-0',
          'w-6 h-6',
          'border-0',
          'rounded-full',
          'text-surface-500',
          'bg-transparent',
          'transition duration-200 ease-in-out',
          'hover:text-surface-700 dark:hover:text-white/80',
          'hover:bg-surface-100 dark:hover:bg-surface-800/80',
          'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-inset',
          'focus:ring-primary-500 dark:focus:ring-primary-400',
          'overflow-hidden'
        ]
      },
      maximizablebutton: {
        class: [
          'relative',
          'flex items-center justify-center',
          'mr-2',
          'last:mr-0',
          'w-6 h-6',
          'border-0',
          'rounded-full',
          'text-surface-500',
          'bg-transparent',
          'transition duration-200 ease-in-out',
          'hover:text-surface-700 dark:hover:text-white/80',
          'hover:bg-surface-100 dark:hover:bg-surface-800/80',
          'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-inset',
          'focus:ring-primary-500 dark:focus:ring-primary-400',
          'overflow-hidden'
        ]
      },
      closeButtonIcon: {
        class: ['inline-block', 'w-3', 'h-3']
      },
      maximizableicon: {
        class: ['inline-block', 'w-3', 'h-3']
      },
      content: ({ state: e, instance: r }: any) => ({
        class: [
          'text-sm',
          'px-6',
          'pb-3',
          'pt-0',
          {
            grow: e.maximized,
            'rounded-bl-lg': !r.$slots.footer,
            'rounded-br-lg': !r.$slots.footer
          },
          'bg-surface-0 dark:bg-surface-800',
          'text-surface-600 dark:text-surface-0/70',
          'overflow-y-auto'
        ]
      }),
      footer: {
        class: [
          'flex items-center justify-end',
          'shrink-0',
          'text-right',
          'gap-3',
          'px-6',
          'py-3',
          'border-t-0',
          'rounded-b-lg',
          'bg-surface-50 dark:bg-surface-700',
          'text-surface-700 dark:text-surface-0/80'
        ]
      },
      mask: ({ props: e }: any) => ({
        class: [
          'transition',
          'duration-300',
          { 'p-5': e.position != 'full' },
          {
            'has-[.mask-active]:bg-transparent dark:has-[.mask-active]:bg-transparent bg-surface-500/70 dark:bg-surface-700/70':
              e.modal,
            'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': e.modal
          }
        ]
      }),
      transition: ({ props: e }: any) =>
        e.position === 'top'
          ? {
              enterFromClass:
                'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active'
            }
          : e.position === 'bottom'
            ? {
                enterFromClass: 'opacity-0 scale-75 translate-y-full mask-active',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass:
                  'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0 mask-active'
              }
            : e.position === 'left' || e.position === 'topleft' || e.position === 'bottomleft'
              ? {
                  enterFromClass:
                    'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0 mask-active',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass:
                    'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0 mask-active'
                }
              : e.position === 'right' || e.position === 'topright' || e.position === 'bottomright'
                ? {
                    enterFromClass:
                      'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
                    enterActiveClass: 'transition-all duration-200 ease-out',
                    leaveActiveClass: 'transition-all duration-200 ease-out',
                    leaveToClass:
                      'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active'
                  }
                : {
                    enterFromClass: 'opacity-0 scale-75 mask-active',
                    enterActiveClass: 'transition-all duration-200 ease-out',
                    leaveActiveClass: 'transition-all duration-200 ease-out',
                    leaveToClass: 'opacity-0 scale-75 mask-active'
                  }
    },
    // Tag
    tag: {
      root: ({ props }) => ({
        class: [
          //Font
          'text-xs font-bold',
          //Alignments
          'inline-flex items-center justify-center',
          //Spacing
          'px-[0.4rem] py-1',
          //Shape
          {
            'rounded-md': !props.rounded,
            'rounded-full': props.rounded
          },
          //Colors
          {
            'bg-highlight': props.severity === null || props.severity === 'primary',
            'text-green-700 dark:text-green-300 bg-green-100 dark:bg-green-500/20':
              props.severity === 'success',
            'text-surface-700 dark:text-surface-300 bg-surface-100 dark:bg-surface-500/20':
              props.severity === 'secondary',
            'text-blue-700 dark:text-blue-300 bg-blue-100 dark:bg-blue-500/20':
              props.severity === 'info',
            'text-orange-700 dark:text-orange-300 bg-orange-100 dark:bg-orange-500/20':
              props.severity === 'warn',
            'text-red-700 dark:text-red-300 bg-red-100 dark:bg-red-500/20':
              props.severity === 'danger',
            'text-surface-0 dark:text-surface-900 bg-surface-900 dark:bg-surface-0':
              props.severity === 'contrast'
          }
        ]
      }),
      value: {
        class: 'leading-normal'
      },
      icon: {
        class: 'mr-1 text-sm'
      }
    }
  },
  {
    mergeSections: true,
    mergeProps: false
  }
)
