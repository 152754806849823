import useAxios from '@/utils/useAxios'
import {
  type OwnerUserDetailsInterface,
  type SharedUserAccessInterface,
  useAuthStore
} from '@/stores/authentication'

interface Profile {
  first_name: string
  last_name: string
  email: string
  date_joined: string
  last_login: string
  industry: string | null
  is_shared: boolean
  shared_access: object | null
  owner_user: object | null
}

export const useFetchProfile = async (access_token: string | undefined) => {
  // Auth Store
  const authStore = useAuthStore()

  const { data, error, status, fetchData } = useAxios<Profile>()

  // Fetch profile
  await fetchData({
    url: 'auth/profile/',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  }).then(() => {
    // Set user email
    data.value?.email && authStore.setUserEmail(data.value?.email)

    // Set user full name
    authStore.setFullName(data.value?.first_name + ' ' + data.value?.last_name)

    // Set hasSetupCustomDomain
    authStore.setHasSetupCustomDomain(data.value?.has_custom_domain || false)

    // set onboarding screen state
    if (data.value?.industry == null) authStore.setShowOnboardingScene(true)

    // set current user is shared user or not.
    data.value?.is_shared && authStore.setIsSharedUser(data.value?.is_shared)

    // Set shared user access
    data.value?.shared_access &&
      authStore.setSharedUserAccess(<SharedUserAccessInterface>data.value?.shared_access)

    // Set owner user details
    data.value?.owner_user &&
      authStore.setOwnerUserDetails(<OwnerUserDetailsInterface>data.value?.owner_user)
  })
  return { data, status }
}
