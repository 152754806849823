import { createRouter, createWebHistory, type Router } from 'vue-router'
import SignupView from '@/views/authentication/SignupView.vue'
import SigninView from '@/views/authentication/SigninView.vue'
import Dashboard from '@/views/DashboardView.vue'
import Error404View from '@/views/error/Error404View.vue'
import ForgetPasswordView from '@/views/authentication/ForgetPasswordView.vue'
import IntegrationView from '@/views/IntegrationView.vue'
import { useAuthStore } from '@/stores/authentication'
import axios from 'axios'
import SettingsView from '@/views/SettingsView.vue'
import CreateVerificationView from '@/views/verification/CreateVerificationView.vue'
import AccountView from '@/views/AccountView.vue'
import SupportView from '@/views/SupportView.vue'
import SSOSigninView from '@/views/authentication/SSOSigninView.vue'
import BulkOperationView from '@/views/bulkOperation/BulkOperationView.vue'
import BulkGenerateView from '@/views/bulkOperation/BulkGenerateView.vue'

const router: Router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      alias: '/dashboard',
      component: Dashboard,
      meta: { requiresAuth: true, title: 'Dashboard' }
    },
    {
      path: '/signin',
      name: 'signin',
      component: SigninView
    },
    {
      path: '/sso',
      name: 'sso',
      component: SSOSigninView
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignupView
    },
    {
      path: '/forget-password',
      name: 'forget-password',
      component: ForgetPasswordView
    },
    {
      path: '/integrations',
      name: 'integration',
      alias: '/integration',
      component: IntegrationView,
      meta: { requiresAuth: true, title: 'Integrations' }
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsView,
      meta: { requiresAuth: true, title: 'Settings' }
    },
    {
      path: '/account',
      name: 'Account',
      component: AccountView,
      meta: { requiresAuth: true }
    },
    {
      path: '/support',
      name: 'support',
      component: SupportView,
      meta: { requiresAuth: true }
    },
    {
      path: '/create',
      name: 'create-verification',
      component: CreateVerificationView,
      meta: { requiresAuth: true }
    },
    {
      path: '/bulk-operation',
      component: BulkOperationView,
      name: 'bulk-operation',
      meta: { requiresAuth: true, title: 'Bulk Operation' }
    },
    {
      path: '/bulk-operation/generate',
      name: 'bulk-generate',
      component: BulkGenerateView,
      meta: { requiresAuth: true, title: 'Generate' }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: Error404View,
      meta: { requiresAuth: true }
    }
  ]
})

// Router Guard
// noinspection JSUnusedLocalSymbols
router.beforeEach((to, from) => {
  // Auth store
  const authStore = useAuthStore()

  // Update access token in the axios headers.
  if (authStore.getAccessToken && Date.now() < Number(authStore.getExpiresIn))
    axios.defaults.headers.common['Authorization'] = `Bearer ${authStore.getAccessToken}`

  // Refresh token will expire in 48 hours. After that, redirecting user to signin page.
  if (!authStore.isAuthenticated() && to.meta.requiresAuth) return { name: 'signin' }

  // If user is authenticated and url is not 404 then `requiresAuth` will redirect to the dashboard.
  if (authStore.isAuthenticated() && !to.meta.requiresAuth && to.name != 'NotFound')
    return { name: 'dashboard' }
})
export default router
